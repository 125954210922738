import consumer from "./consumer";

consumer.subscriptions.create("RoomChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("Connected.");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    var pathArray = window.location.pathname.split("/");
    if (pathArray[2] != data.slug) return;
    // Called when there's incoming data on the websocket for this channel
    $("#msg").append('<div class="message">' + data.content + "</div>");
    console.log(data);
  }
});

// $.getJSON(
//   "https://secure.geobytes.com/GetCityDetails?key=235dcf935c0c92cd2b4ea3c0a7789205&callback=?",
//   function(data) {
//     $("#message_content").val(JSON.stringify(data.geobytesregion));
//     $("#new_message").submit();
//   }
// );

// const submit_messages = () => {
//   $("#message_content").on("keydown", e => {
//     if (e.keyCode === 13) {
//       console.log("Yes we hit enter");
//     }
//   });
// };

function getLocation() {
  var pathArray = window.location.pathname.split("/");
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition);
  }
  $.get("/getinfo/" + pathArray[2] + "/", function(data, textStatus, jqXHR) {
    // success callback
    console.log("status: " + textStatus + ", data:" + data);
  });
  $("#message_slug").val(pathArray[2]);
}

function showPosition(position) {
  $("#message_content").val(
    "Latitude: " +
      position.coords.latitude +
      " Longitude: " +
      position.coords.longitude +
      " => <a href='https://www.latlong.net/c/?lat=" +
      position.coords.latitude +
      "&long=" +
      position.coords.longitude +
      "' target='_blank'>View Map</a>"
  );
  $("#new_message").submit();
}

$(document).on("turbolinks:load", function() {
  var pathArray = window.location.pathname.split("/");
  if (pathArray[1] == "v") {
    getLocation();
  }
});
