// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("jquery");
require("@rails/activestorage").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

function getProtocol() {
  let protocol = window.location.href.split("/");
  return protocol[0];
}
function getUrl() {
  let url = window.location.href.split("/");
  return url[2];
}

function makeid(length) {
  var result = "";
  var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function showSession() {
  const newSeesionID = makeid(3);
  window.location.href = `${getProtocol()}//${getUrl()}/x/${newSeesionID}`;
  // $("#newSessionSpan").html(
  //   `<samp><strong>verifie:</strong> ${getProtocol()}//${getUrl()}/v/${newSeesionID}</samp><br /><samp><strong>verifier:</strong> ${getProtocol()}//${getUrl()}/x/${newSeesionID}</samp>`
  // );
}

function getVeryLink() {
  var pathArray = window.location.pathname.split("/");
  console.log("here");
  $("#verfLink").val(`${getProtocol()}//${getUrl()}/v/${pathArray[2]}`);
}

function copy() {
  /* Get the text field */
  var copyText = document.getElementById("verfLink");

  /* Select the text field */
  copyText.select();
  copyText.setSelectionRange(0, 99999); /*For mobile devices*/

  /* Copy the text inside the text field */
  document.execCommand("copy");

  /* Alert the copied text */
  alert("Copied the link: " + copyText.value);
}

$(document).on("turbolinks:load", function() {
  $("#newSessionBtn").click(function() {
    showSession();
  });

  $("#verfLink").click(function() {
    copy();
  });

  getVeryLink();
});
